#time-log-list {
    table {
        thead {
            th:nth-child(2) {
                width: 150px;
            }
            th:nth-child(3) {
                width: 100px;
            }
        }
    }
}