@import './variables.scss';
@import './metronic-theme.scss';

body {
    /*Cutom Only*/

    .bilot-bilot {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 5px;
    }

    .kt-splash-screen {
      background-color: #fff;
    }

    .btn-rounded.btn-clean {
      border-radius: 50px;
      background-color: #F64E60 !important;
      transition: all 500ms ease-in-out !important;

      & i {
        color: #fff;
      }

      &:hover {
        background-color: #F3F6F9 !important;
        border-radius: 3px;

        i {
          color: #F64E60 !important;
        }
      }
    }

    .react-datepicker__week-number {
      font-weight: 600;
      color: #000 !important;
    }

    .form-week-number {
      .react-datepicker-popper {
        z-index: 100;
  
        .react-datepicker__week:has(.react-datepicker__week-number):has(.react-datepicker__day:not(.react-datepicker__day--disabled):hover, .react-datepicker__day--selected) {
          .react-datepicker__week-number {
            border-radius: 15px;
            background-color: $primary !important;
            color: #fff !important;
          }
          .react-datepicker__day {
            background-color: $primary !important;
            color: #fff !important;
            margin: 0.166rem 0;
            padding: 0 0.166rem;
            box-sizing: content-box;
          }
        }
      }
    }


    .select-label-between {
      .select-text {
        display: block;
        width: 100%;
      }
    }

    .check-list-remove-pad-first:first-child {
      padding-left: 18px;
    }

    .fs-11 {
      font-size: 11px !important;
    }

    /*Cutom Only*/
}