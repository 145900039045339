.chrome-container {
    padding: 0;
    position: relative;
    width: 500px;
    height: 600px;
    // overflow-x: hidden;
    overflow-y: auto;
    

    &-header {
        position: sticky;
        top: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 2px 5px 0px #00000013;
        z-index: 1;

        &-branding {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0.75rem;
            background-color: #ff9393;
            font-weight: 500;
            font-family: cursive;

            img {
                height: 30px;
            }
        }

        &-form {
            padding: 0.75rem;
        }

        .select-dropdown.disabled .select-toggle:not(input), .form-control:disabled, .form-control[readonly] {
            background-color: #fff;
            opacity: 1;
            cursor: not-allowed;
        }
    }

    #time-log-list {
        // margin-top: 100px;
        .grid-table {
            box-shadow: 0px 2px 5px 0px #00000013;
            .table {
                &.table-head-bg thead th {
                    border-radius: 0;
                }
            }
        }
    }


}